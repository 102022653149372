import { ArrowRight } from '@creditas/icons/dist/navigation'
import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

const Wrapper = styled.div`
  width: 220px;
  transition: box-shadow 0.4s;
  box-shadow: ${selectTheme('shadow.xs')};
  border-radius: ${selectTheme('radius.6xl')};
  cursor: pointer;

  ${media.up('5xl')} {
    width: 184px;
  }

  &:hover,
  &:active {
    cursor: pointer;
    border-color: inherit;
    box-shadow: ${selectTheme('shadow.sm')};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const Title = styled.a`
  padding: ${selectTheme('spacingX.6xl')};
  ${getTypography('bodyXlMedium')};
  color: ${selectTheme('colors.neutral.90')};
  text-decoration: none;

  ${media.up('5xl')} {
    ${getTypography('bodyMdMedium')};
  }
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${selectTheme('spacingX.6xl')} ${selectTheme('spacingBottom.6xl')};
  ${getTypography('bodySmMedium')};
  color: ${selectTheme('colors.primary.60')};
  text-decoration: none;
`

const ArrowIcon = styled(ArrowRight)`
  fill: ${selectTheme('colors.primary.60')};
  margin-left: 10px;
`

export { Wrapper, Content, Title, Link, ArrowIcon }
