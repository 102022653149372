import { arrayOf, shape, string } from 'prop-types'

import { Container } from 'components/Container'

import { Card } from './components/Card'

import { List, Title, Wrapper } from './styles'

const propTypes = {
  backgroundColor: string,
  linksList: arrayOf(
    shape({
      linkText: string,
      linkUrl: string,
      title: string,
    }),
  ),
  title: string,
  titleDisplay: string,
}

const RelatedLinks = ({
  backgroundColor: componentBackgoundColor,
  linksList,
  title,
  titleDisplay,
  ...others
}) => {
  const currentTitle = titleDisplay || title

  return (
    <Wrapper {...others}>
      <Container>
        {currentTitle && (
          <Title
            dangerouslySetInnerHTML={{
              __html: currentTitle,
            }}
          />
        )}

        {linksList?.length && (
          <List>
            {linksList?.map(
              ({ linkText, linkUrl, title: cardTitle }, index) => (
                <Card
                  key={`related-link-${index}`}
                  index={index}
                  title={cardTitle}
                  linkText={linkText}
                  linkUrl={linkUrl}
                />
              ),
            )}
          </List>
        )}
      </Container>
    </Wrapper>
  )
}

RelatedLinks.propTypes = propTypes

export { RelatedLinks }
export default RelatedLinks
