import { number, string } from 'prop-types'
import { navigate } from 'gatsby'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ArrowIcon, Content, Link, Title, Wrapper } from './styles'

const propTypes = {
  index: number,
  linkText: string,
  linkUrl: string,
  title: string,
}

const Card = ({ index, linkText, linkUrl, title, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  const ABSOLUT_URI = new RegExp('^(https|http)?(://)')
  const url = ABSOLUT_URI.test(linkUrl)
    ? linkUrl
    : `${process.env.PORTAL_DOMAIN_NAME}${linkUrl}`

  const handleClick = () => {
    triggerEvent({
      label: `${index + 1} | ${linkText}`,
      pageInteraction: false,
    })
    navigate(url)
  }

  return (
    <Wrapper onClick={handleClick} {...others}>
      <Content>
        {title && <Title href={url}>{title}</Title>}

        {linkText && (
          <Link href={url}>
            {linkText}
            <ArrowIcon />
          </Link>
        )}
      </Content>
    </Wrapper>
  )
}

Card.propTypes = propTypes

export { Card }
