import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

const Wrapper = styled.section`
  width: 100%;
  padding: 32px 0 0;
  overflow: hidden;
  display: inline-block;
  margin: ${selectTheme('spacingY.6xl')} 0;

  ${media.up('5xl')} {
    margin: ${selectTheme('spacingY.12xl')} 0;
  }
`

const Title = styled.div`
  max-width: 450px;
  margin: auto;
  color: ${selectTheme('colors.neutral.90')};
  padding-bottom: 30px;
  text-align: left;
  ${getTypography('headingLgLight')};

  ${media.up('5xl')} {
    text-align: center;
  }

  p {
    margin: ${selectTheme('spacingY.5xl')} 0;
  }
`

const List = styled.div`
  width: 100vw;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-template-columns: none;
  grid-column-gap: 8px;
  overflow-y: auto;
  padding: ${selectTheme('spacingY.5xl')};
  margin-left: -${selectTheme('spacingLeft.5xl')};
  justify-content: flex-start;

  ${media.up('5xl')} {
    width: inherit;
    overflow-y: initial;
    grid-column-gap: 24px;
    justify-content: center;
  }
`

export { Wrapper, Title, List }
